import packageJson from '../package.json'

export type Config = {
  APP_NAME: string
  API_URL: string
  BUILD_VERSION: string
  ERC20_CONTRACT_ADDRESS: string
}

export const config: Config = {
  APP_NAME: import.meta.env.VITE_APP_NAME,
  API_URL: import.meta.env.VITE_API_URL,
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,
  ERC20_CONTRACT_ADDRESS: '0x5a87b9E3B83DfEc150A1A2E13dbA68590ea2907B',
}
