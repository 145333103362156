import { createStore } from '@/helpers'

type Erc20List = {
  contractAddresses: string[]
}

export const [erc20ListStore, useErc20ListState] = createStore(
  'erc20-list',
  {
    contractAddresses: [],
  } as Erc20List,
  state => ({
    addItem: (addr: string) => {
      if (!state.contractAddresses.includes(addr)) {
        state.contractAddresses.push(addr)
      }
    },
    removeItem: (addr: string) => {
      const index = state.contractAddresses.map(el => el.toLowerCase()).indexOf(addr.toLowerCase())

      if (index !== -1) {
        state.contractAddresses.splice(index, 1)
      }
    },
  }),
)
